import React from "react";
import './Hero.css'
import Header from "../Header/Header";
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
//import {motion} from 'framer-motion';
const Hero = () => {
    return (
        <div className="hero">

            <div className="blur hero-blur"></div>
            <div className="left-h">
                <Header/>
                {/* the best add*/}
                <div className="the-best-ad">
                <div></div>
                <span>The best fitness club in town</span>
                </div>
                {/*Hero Heading*/}
                <div className="hero-text">
                    <div>
                        <spam className='stroke-text'>Shape </spam>
                        <spam>Your</spam>
                    </div>
                    <div>
                        <spam>Ideal body</spam>                    
                    </div>
                    <div>
                        <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
                    </div>
                </div>
                {/*figures*/}
                <div className="figures">
                    <div>
                        <span>+150</span>
                        <span>expert coaches</span>
                    </div>
                    <div>
                        <span>+979</span>
                        <span>members joined</span>
                    </div>
                    <div>
                        <span>+50</span>
                        <span>fitness program</span>
                    </div>
                </div>
                {/*buttons */}
                <div className="hero-button">
                    <button className="btn">get started</button>
                    <button className="btn">learn more</button>
                </div>
            </div>
            <div className="right-h">
                <button className="btn">join now</button>
                {/* hero image */}
                <div className="heart-rate">
                    <img src={Heart} alt='' />
                    <span>Heart rate</span><span>116 bpm</span>
                </div>
                <img src={hero_image} alt="" className="hero_image" />
                <img src={hero_image_back} alt='' className="hero_image_back" />
                {/* calories */}
                <div className="calories">
                    <img src={Calories} alt='' />
                    <div>
                    <span>Calories burned</span><span>230 kcal</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Hero;